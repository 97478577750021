import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Container,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imgNavLogoDishup } from '../../landingpage/src/assets'
import ArrowBack from "@material-ui/icons/ArrowBack";
// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTermsConditionsDetails = () => {
    const { termsConditions }: any = this.state;
    return (
      <>
        <div style={{ marginTop: "30px"}}>
          <Typography
           variant="h5"
            style={{fontFamily: "Inter", fontWeight: 700, width: "100%", maxWidth: "450px", fontSize: "clamp(16px, 5vw, 24px)" }}
          >Terms & Conditions</Typography>
          <div style={{
            fontFamily: "SF Pro",
            fontSize: "clamp(14px, 4vw, 20px)",
          }} dangerouslySetInnerHTML={{ __html: termsConditions }} />
        </div>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={uiTheme}>
        <div style={{ overflow: "auto" }}>
          <AppBar position="static">
            <Toolbar style={{
              height: "90px",
              justifyContent: "space-between"
            }}
              className={uiStyles.apptoolBarHeightStyle}
            >
              <Box>
                <img style={{
                  left: "140px",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "clamp(100px, 20vw, 224px)",
                  height: "clamp(30px, 7vw, 54px)",
                }}
                  className={uiStyles.appdishUpLogoStyle}
                  src={imgNavLogoDishup}
                  alt={configJSON.dishUpAlt}
                  data-test-id={"dishup-logo"}
                />
              </Box>
            </Toolbar>
          </AppBar>  
          <div style={{ marginTop: "30px", marginRight:"10%"}} className={uiStyles.appparentDiv}>
            <Container className={uiStyles.appcontainerWrapper} >
              <div>
                <Button style={{
                  width: "165px",
                  height: "44px",
                  fontFamily: "SF Pro",
                  fontStyle: "normal",
                  marginTop: "30px",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#000000",
                  borderRadius: "20px",
                  padding: "0px 16px",
                  border: "1px solid #E5E5E5"
                }}
                  onClick={() => this.navigateToLandingPage()}
                  data-test-id={"landing-page"}
                >
                  <ArrowBack />&nbsp; 
                  <Typography style={{fontWeight:400}}>Go back</Typography>
                </Button>
              </div>
              {this.renderTermsConditionsDetails()}
            </Container>
          </div>    
        </div>
      </ThemeProvider>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const uiTheme = createTheme({
  palette: {
    primary: {
      main: "#F3E344",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        colorInherit: "#F3E344",
        textTransform: "none" as const
      }
    }
  }
});

export const uiStyles: any = (theme: any) => ({
  apptoolBarHeightStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    height: "90px",
    margin: "12rem auto 2rem auto",
    [theme.breakpoints.down("sm", "xs")]: {
      minHeight: "90px",
      flexDirection: "column",
      justifyContent: "center",
      margin: "1rem 0 0"
    },
  },
  appdishUpLogoStyle: {
    width: "224px",
    height: "54px",
    left: "140px",
    top: "18px",
    margin: "12rem auto 2rem auto",
    position: "absolute" as const,
    [theme.breakpoints.down("sm", "xs")]: {
      height: "27px",
      width: "113px",
      marginBottom: "5px",
      position: "absolute" as const,
      transform: "translate(-50%, -50%)",
      left: "50%",
      top: "50%"
    }, 
  },
  appparentDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    background: `linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,1))`,
    backgroundSize: "cover" as const,
    backgroundRepeat: "no-repeat" as const,
    minHeight: "calc(100vh - 90px)",
    [theme.breakpoints.down("sm", "xs")]: {
      background: `linear-gradient(to left, transparent, rgba(0,0,0,1)))`,
      backgroundSize: "cover" as const,
      backgroundPosition: "center center"
    }
  },
  appcontainerWrapper: {
    display: "flex",
    width: "calc(100vw - 170px)",
    justifyContent: "space-between",
    margin: "12rem auto 2rem auto",
    "@media(max-width: 1920px)": {
      marginTop: "6rem"
    },
    "@media(max-width: 1368px)": {
      marginTop: "2rem"
    },
    "@media(max-width: 600px)": {
      width: "100%"
    },
    "&.MuiContainer-root": {
      paddingLeft: "1px",
      paddingRight: "1px",
      maxWidth: "100%"
    },
    "@media screen and (max-width: 1600px) and (max-height: 900px)": {
      marginTop: "2.5rem"
    },
    [theme.breakpoints.down("sm", "xs")]: {
      flexDirection: "column",
      margin: "3rem auto",
      "&.MuiContainer-root": {
        marginTop: "180px"
      }
    }
  },
})
// Customizable Area End
